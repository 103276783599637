export const BASE_URL =
  window.location.host.indexOf("localhost") < 0
    ? window.location.protocol + "//" + window.location.host
    : "https://bmw.rateose.store";

export const STORY_URL =
  window.location.host.indexOf("localhost") < 0
    ? "https://story.gift-mi.site"
    : "https://story.rateose.store";

export const url =
  window.location.host.indexOf("localhost") < 0
    ? window.location.host.replace("bmw", "story")
    : "story.rateose.store";

export default {
  STORY_LIST: "/api/Tiezi/getTiezilist",
  STORY_DETAIL: "/api/Tiezi/getTiezi",
  COMMENT_LIST: "/api/Pinglun/getPinglunlisttiezi",
  UPLOAD: "/api/common/upload",
  GET_PIXEL: "/api/index/getpixel",
  FACEBOOK: "/api/facebook/EventsAll",
  SUBMIT: "/api/index/index",
  UPDATE: "/api/index/updateOrder",
  CUSTOMSTATUS: "/api/index/customstatus",
  ADDRESS: "/api/jpaddress/address",
};
