import React, { Component } from "react";
import BMW from "../image/xiaomi.png";
import Call from "../icon/Call";
import Message from "../icon/Message";
import Telegram from "../icon/Telegram";
import Twtter from "../icon/Twtter";
import Facebook from "../icon/Facebook";
import "../style/fail.scss";

import share4 from "../image/share4.png";
import CloseIcon from "../icon/CloseIcon";
import Close from "../icon/Close";
import Lihua from "../icon/Lihua";
import Line from "../image/line-jp.png";
import MobileDetectUtil from "../utils/MobileDetectUtil";

import sahua from "../image/sahua.gif";
import * as CountPixelEventAPI from "../apis/CountPixelEventAPI";
import { url } from "../constants/ApiEndpointConstants";

import { BASE_URL } from "../constants/ApiEndpointConstants";
const invitation_text =
  "無料で入手Redmi BudsクレームイユーＳ 15g，限定5000枚、すぐに！";
const HTTP_REQUEST_URL =
  window.location.protocol + "//" + window.location.host + "?ad=share";

class Fail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      share: [
        {
          title: "Line",
          url: `https://line.me/R/share?text=${encodeURIComponent(
            invitation_text + " " + HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "WhatsApp",
          url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
            `${invitation_text} ${HTTP_REQUEST_URL}`
          )}&url=${encodeURIComponent(HTTP_REQUEST_URL)}`,
          isClick: false,
        },
        {
          title: "Sms",
          url: `sms:${
            MobileDetectUtil.judgeClient() === "iOS" ? "&" : "?"
          }body=${encodeURIComponent(
            invitation_text + " " + HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Telegram",
          url: `https://telegram.me/share/url?text=${invitation_text}&url=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Twitter",
          url: `https://twitter.com/intent/tweet?lang=en&text=${invitation_text}&url=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Facebook",
          url: `https://www.facebook.com/dialog/share?app_id=407682420960&display=popup&href=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
      ],
      shareClickNum: null || JSON.parse(localStorage.getItem("shareClickNum")),
      show: false,
    };
    this.routeHome = this.routeHome.bind(this);
    this.clivkToShare = this.clivkToShare.bind(this);
    this.closeShareMask = this.closeShareMask.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem("drawCount");
    localStorage.removeItem("share");
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    this.setState({ product: orderDetail.vehicleModel });
    if (localStorage.getItem("shareClickNum")) {
      let shareClickNum = JSON.parse(localStorage.getItem("shareClickNum"));
      this.setState({
        shareClickNum: parseInt(shareClickNum),
      });
    }
  }

  routeHome() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: "/Model",
        search: location.search,
      });
  }

  clivkToShare(index) {
    let ad = JSON.parse(localStorage.getItem("ad"));
    CountPixelEventAPI.countPixelEvent(
      BASE_URL.replace("https://", ""),
      ad,
      "ClickTheShareButton"
    ).then((res) => {
      console.log(res);
    });
    let { share, shareClickNum } = this.state;
    if (shareClickNum === 2) {
      window.open(share[index].url, "_blank");
      setTimeout(() => {
        this.setState({ show: true }, () => {
          shareClickNum = parseInt(shareClickNum) + 1;
          this.setState({ shareClickNum: parseInt(shareClickNum) });
        });
      }, 1000);
      localStorage.setItem("shareClickNum", JSON.stringify(shareClickNum));
      return;
    }
    if (shareClickNum === 4) {
      window.open(share[index].url, "_blank");
      setTimeout(() => {
        this.setState({ show: true }, () => {
          shareClickNum = parseInt(shareClickNum) + 1;
          this.setState({ shareClickNum: parseInt(shareClickNum) });
        });
      }, 1000);
      localStorage.setItem("shareClickNum", JSON.stringify(shareClickNum));
      return;
    }
    window.open(share[index].url, "_blank");
    console.log(shareClickNum);
    if (shareClickNum === null) {
      shareClickNum = 1;
    } else {
      shareClickNum = parseInt(shareClickNum) + 1;
    }

    localStorage.setItem("shareClickNum", JSON.stringify(shareClickNum));
    setTimeout(() => {
      this.setState({ shareClickNum: parseInt(shareClickNum) }, () => {
        if (this.state.shareClickNum >= 7) {
          window.open(share[index].url, "_blank");
          setTimeout(() => {
            this.setState({ show: true });
          }, 1000);
        }
      });
    }, 1000);
  }

  closeShareMask() {
    this.setState({ show: !this.state.show });
  }

  render() {
    const { shareClickNum, show } = this.state;
    return (
      <React.Fragment>
        <div className={`share-mask ${show ? "show" : ""}`}>
          <img
            src={shareClickNum >= 7 ? sahua : ""}
            alt=""
            className="sahua "
          />
          <div className="share-textBox">
            {" "}
            <Close
              size={30}
              color="rgb(188, 147, 73)"
              className="close-icon"
              onClick={this.closeShareMask}
            />
            <div className="lihua-icon">
              {shareClickNum >= 7 ? <Lihua size={100} /> : ""}
            </div>
            <div className="title">
              {shareClickNum >= 7 ? "成功を分かち合う！" : ""}
            </div>
            <div className="desc">
              {shareClickNum === 3
                ? "別の友人にシェアしてください。同じ友人にシェアしても統計は行われません。"
                : ""}
              {shareClickNum === 5 ? "グループチャットも共有できる" : ""}
              {shareClickNum >= 7 ? "Redmi Buds当選おめでとうございます！" : ""}
            </div>
          </div>
        </div>
        <div className="fail-page">
          <div className="head-logo">
            <img src={BMW} alt="" />
          </div>
          <p className="tip">支払いを怠る! 後でもう一度お試しください。</p>
          <div className="pro">
            <img src={share4} alt="" />
          </div>
          <p>友達5人とLINEをシェアしてRedmi Budsをゲット！</p>
          <div className="icon-row">
            <div className="li-icon" onClick={() => this.clivkToShare(0)}>
              <img src={Line} alt="" />
            </div>
            {/* <div className="icon li-icon">
              <Call
                size={70}
                className="li-icon"
                onClick={() => this.clivkToShare(1)}
              />
            </div> */}
            {/* <Message
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(2)}
            /> */}
            {/* <Telegram
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(3)}
            />
            <Twtter
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(4)}
            />
            <Facebook
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(5)}
            /> */}
          </div>
          {shareClickNum !== null ? (
            <div className="loading-line">
              <div
                className={`bgc-line 
                ${shareClickNum === 1 && "w20"} 
                ${shareClickNum === 2 && "w40"}
                ${shareClickNum === 3 && "w40"} 
                ${shareClickNum === 4 && "w60"} 
                ${shareClickNum === 5 && "w60"} 
                ${shareClickNum === 6 && "w80"} 
                ${shareClickNum >= 7 && "w100"}`}
              >
                {shareClickNum === 1 && "20%"}
                {shareClickNum === 2 && "40%"}
                {shareClickNum === 3 && "40%"}
                {shareClickNum === 4 && "60%"}
                {shareClickNum === 5 && "60%"}
                {shareClickNum === 6 && "80%"}
                {shareClickNum >= 7 && "100%"}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <div className="btn" onClick={this.routeHome}>
            ホームへ戻る
          </div> */}
        </div>
      </React.Fragment>
    );
  }
}

export default Fail;
