import React, { Component } from "react";
import "../style/success.scss";
import BMW from "../image/xiaomi.png";
import Call from "../icon/Call";
import Message from "../icon/Message";
import Telegram from "../icon/Telegram";
import Twtter from "../icon/Twtter";
import Facebook from "../icon/Facebook";
import queryString from "query-string";
import * as CustomStatusAPI from "../apis/CustomStatusAPI";
import * as FacebookAPI from "../apis/FacebookAPI";
import MobileDetectUtil from "../utils/MobileDetectUtil";
import Line from "../image/line-jp.png";
import { BASE_URL } from "../constants/ApiEndpointConstants";

import Close from "../icon/Close";
import Lihua from "../icon/Lihua";
import sahua from "../image/sahua.gif";
import share4 from "../image/share4.png";
import CloseIcon from "../icon/CloseIcon";
import * as CountPixelEventAPI from "../apis/CountPixelEventAPI";

const invitation_text =
  "無料で入手Redmi BudsクレームイユーＳ 15g，限定5000枚、すぐに！";
const HTTP_REQUEST_URL =
  window.location.protocol + "//" + window.location.host + "?ad=share";

class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      share: [
        {
          title: "Line",
          url: `https://line.me/R/share?text=${encodeURIComponent(
            invitation_text + " " + HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "WhatsApp",
          url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
            `${invitation_text} ${HTTP_REQUEST_URL}`
          )}&url=${encodeURIComponent(HTTP_REQUEST_URL)}`,
          isClick: false,
        },
        {
          title: "Sms",
          url: `sms:${
            MobileDetectUtil.judgeClient() === "iOS" ? "&" : "?"
          }body=${encodeURIComponent(
            invitation_text + " " + HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Telegram",
          url: `https://telegram.me/share/url?text=${invitation_text}&url=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Twitter",
          url: `https://twitter.com/intent/tweet?lang=en&text=${invitation_text}&url=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Facebook",
          url: `https://www.facebook.com/dialog/share?app_id=407682420960&display=popup&href=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
      ],
      shareClickNum: null || JSON.parse(localStorage.getItem("shareClickNum")),
      show: false,
    };
    this.routeHome = this.routeHome.bind(this);
    this.clivkToShare = this.clivkToShare.bind(this);
    this.closeShareMask = this.closeShareMask.bind(this);
  }

  componentDidMount() {
    let { location } = this.props;
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    let TimeOut = setInterval(() => {
      if (window.fbq) {
        console.log("fbq");
        clearInterval(TimeOut);
        let event_id = Math.round(Math.random() * 10000000000);
        window.fbq(
          "track",
          "Purchase",
          {
            content_name: orderDetail.vehicleModel,
            content_ids: [orderDetail.vehicleModel],
            currency: "JPY",
            contents: [
              {
                title: orderDetail.vehicleModel,
                id: orderDetail.vehicleModel,
                quantity: "1",
              },
            ],
            value: 500,
          },
          { eventID: event_id }
        );
        let customdata = {
          content_name: orderDetail.vehicleModel,
          content_ids: [orderDetail.vehicleModel],
          currency: "JPY",
          contents: [
            {
              title: orderDetail.vehicleModel,
              id: orderDetail.vehicleModel,
              quantity: "1",
            },
          ],
          value: 200,
        };
        let userdata = {
          em: orderDetail.email,
          ph: orderDetail.phone,
          fn: orderDetail.firstName,
          ln: orderDetail.lastName,
          ct: "JP",
          st: orderDetail.state,
          zp: orderDetail.zipCode,
          country: orderDetail.country,
          external_id: "",
          client_ip_address: "",
          client_user_agent: "",
          fbc: "",
          fbp: "",
          lead_id: "",
          anon_id: "",
          madid: "",
          page_id: "",
          page_scoped_user_id: "",
          ctwa_clid: "",
          ig_account_id: "",
          ig_sid: "",
        };
        FacebookAPI.Facebook(
          event_id,
          "Purchase",
          customdata,
          userdata,
          orderDetail.fbqToken,
          orderDetail.pixel
        ).then((res) => {
          console.log(res);
          // fb
          CountPixelEventAPI.countPixelEvent(
            BASE_URL.replace("https://", ""),
            orderDetail.ad,
            "Purchase"
          ).then((res) => {
            console.log(res);
          });
          // 清楚缓存;
          localStorage.removeItem("isDraw");
          localStorage.removeItem("share");
          localStorage.removeItem("drawCount");
          localStorage.removeItem("isDraw");
          // localStorage.removeItem("orderDetail");
        });
      }
    }, 3000);
    this.setState({ product: orderDetail.vehicleModel });
    if (localStorage.getItem("shareClickNum")) {
      let shareClickNum = JSON.parse(localStorage.getItem("shareClickNum"));
      this.setState({
        shareClickNum: parseInt(shareClickNum),
      });
    }
    if (location.search !== "") {
      let arr = queryString.parse(location.search);
      console.log(arr);
      this.setState({ email: arr.email, phone: arr.phone });

      CustomStatusAPI.customStatus(
        arr.payment_intent_id,
        arr.status,
        arr.c_site_url,
        arr.subscriptionId
      ).then((res) => {
        console.log(res);
        var data = res.data.data;
        console.log(data);
        if (data.status === "succeeded") {
        }
      });
    }
  }

  routeHome() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: "/Model",
        search: location.search,
      });
  }

  clivkToShare(index) {
    let ad = JSON.parse(localStorage.getItem("ad"));
    CountPixelEventAPI.countPixelEvent(
      BASE_URL.replace("https://", ""),
      ad,
      "ClickTheShareButton"
    ).then((res) => {
      console.log(res);
    });
    let { share, shareClickNum } = this.state;
    if (shareClickNum === 2) {
      window.open(share[index].url, "_blank");
      setTimeout(() => {
        this.setState({ show: true }, () => {
          shareClickNum = parseInt(shareClickNum) + 1;
          this.setState({ shareClickNum: parseInt(shareClickNum) });
        });
      }, 1000);
      localStorage.setItem("shareClickNum", JSON.stringify(shareClickNum));
      return;
    }
    if (shareClickNum === 4) {
      window.open(share[index].url, "_blank");
      setTimeout(() => {
        this.setState({ show: true }, () => {
          shareClickNum = parseInt(shareClickNum) + 1;
          this.setState({ shareClickNum: parseInt(shareClickNum) });
        });
      }, 1000);
      localStorage.setItem("shareClickNum", JSON.stringify(shareClickNum));
      return;
    }
    window.open(share[index].url, "_blank");
    console.log(shareClickNum);
    if (shareClickNum === null) {
      shareClickNum = 1;
    } else {
      shareClickNum = parseInt(shareClickNum) + 1;
    }

    localStorage.setItem("shareClickNum", JSON.stringify(shareClickNum));
    setTimeout(() => {
      this.setState({ shareClickNum: parseInt(shareClickNum) }, () => {
        if (this.state.shareClickNum >= 7) {
          window.open(share[index].url, "_blank");
          setTimeout(() => {
            this.setState({ show: true });
          }, 1000);
        }
      });
    }, 1000);
  }

  closeShareMask() {
    this.setState({ show: !this.state.show });
  }
  render() {
    const { email, phone, shareClickNum, show } = this.state;
    return (
      <React.Fragment>
        <div className={`share-mask ${show ? "show" : ""}`}>
          <img src={shareClickNum >= 7 ? sahua : ""} alt="" className="sahua" />
          <div className="share-textBox">
            <Close
              size={30}
              color="rgb(188, 147, 73)"
              className="close-icon"
              onClick={this.closeShareMask}
            />
            <div className="lihua-icon">
              {shareClickNum >= 7 ? <Lihua size={100} /> : ""}
            </div>
            <div className="title">
              {shareClickNum >= 7 ? "成功を分かち合う！" : ""}
            </div>
            <div className="desc">
              {shareClickNum === 3
                ? "別の友人にシェアしてください。同じ友人にシェアしても統計は行われません。"
                : ""}
              {shareClickNum === 5 ? "グループチャットも共有できる" : ""}
              {shareClickNum >= 7 ? "Redmi Buds当選おめでとうございます！" : ""}
            </div>
          </div>
        </div>
        <div className="success-page">
          <div className="head-logo">
            <img src={BMW} alt="" />
          </div>
          <p>
            ご注文ありがとうございました! 電子メールを送信します
            <span>{email}</span> 注文状況を確認する。 同時に
            同時にお客様の電話番号を <span>{phone}</span> 5営業日以内
            営業日以内にご回答ください。
          </p>
          <div className="pro">
            <img src={share4} alt="" />
          </div>
          <p>友達5人とLINEをシェアしてRedmi Budsをゲット！</p>
          <div className="icon-row">
            <div className="li-icon" onClick={() => this.clivkToShare(0)}>
              <img src={Line} alt="" />
            </div>
            {/* <div className="icon li-icon">
              <Call
                size={70}
                className="li-icon"
                onClick={() => this.clivkToShare(1)}
              />
            </div> */}
            {/* <Message
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(2)}
            /> */}
            {/* <Telegram
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(3)}
            />
            <Twtter
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(4)}
            />
            <Facebook
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(5)}
            /> */}
          </div>
          {shareClickNum !== null ? (
            <div className="loading-line">
              <div
                className={`bgc-line 
                ${shareClickNum === 1 && "w20"} 
                ${shareClickNum === 2 && "w40"}
                ${shareClickNum === 3 && "w40"} 
                ${shareClickNum === 4 && "w60"} 
                ${shareClickNum === 5 && "w60"} 
                ${shareClickNum === 6 && "w80"} 
                ${shareClickNum >= 7 && "w100"}`}
              >
                {shareClickNum === 1 && "20%"}
                {shareClickNum === 2 && "40%"}
                {shareClickNum === 3 && "40%"}
                {shareClickNum === 4 && "60%"}
                {shareClickNum === 5 && "60%"}
                {shareClickNum === 6 && "80%"}
                {shareClickNum >= 7 && "100%"}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <div className="btn" onClick={this.routeHome}>
            ホームへ戻る
          </div> */}
        </div>
      </React.Fragment>
    );
  }
}

export default Success;
